<template>
  <div class="tax-relief">
    <div>
      <p><small v-html="$t('donationForm.additionalInfo.taxRelief.description')" /></p>
      <template v-if="showCalc">
        <p
          v-if="ineligble"
          class="relief-calc"
          v-html="$t('donationForm.additionalInfo.taxRelief.ineligible')"
        />
        <p
          v-else
          class="relief-calc"
          v-html="$t('donationForm.additionalInfo.taxRelief.calculatedTaxRelief', { reducedAmount, deduction })"
        />
      </template>
      <p>TESTING: reducedAmount = {{ reducedAmount }}</p>
      <p>TESTING: deduction = {{ deduction }}</p>
      <p>TESTING: businessRelief = {{ businessRelief }}</p>
    </div>
  </div>
</template>

<script>
import { useDonationStore } from '@/stores/donation'
import { useL10nStore } from '@/stores/l10n'
import currency from 'currency.js'

export default {
  name: 'TaxReliefCalculator',
  setup () {
    const donationStore = useDonationStore()
    const l10nStore = useL10nStore()
    return { donationStore, l10nStore }
  },
  computed: {
    locationKey () { return this.$l10n.currentLocation.key },
    currentAmount () { return currency(this.donationStore.amount) },
    currentCurrency () { return this.donationStore.currency },
    ineligble () { return (this.locationKey === 'it') ? (this.currentAmount.value > 30000) : false },
    taxRelief () {
      let reliefObj = { individual: { reducedAmount: 0, deduction: 0 }, business: 0 }
      if (this.locationKey === 'es') { reliefObj = this.esTaxCalc }
      if (this.locationKey === 'fr') { reliefObj = this.frTaxCalc }
      if (this.locationKey === 'it') { reliefObj = this.itTaxCalc }
      return reliefObj;
    },
    reducedAmount () { return this.formatCurrency(this.taxRelief.individual.reducedAmount, this.currentCurrency) },
    deduction () { return this.formatCurrency(this.taxRelief.individual.deduction, this.currentCurrency) },
    businessRelief () { return this.formatCurrency(this.taxRelief.business, this.currentCurrency) },
    showCalc () { return (this.currentCurrency && (this.currentAmount.value > 0)) },
    esTaxCalc () {
      /*
        If donor is an individual: 
        80% any amount up to 250€ included.
        40% if any amount >250€
        Example for a 300€ donation, result is 220€:
            200€ for the first 250€ (80%)
            20€  for the other 50€ (40%)
        If a company: 40% of any donation.
     */
      if (!this.currentAmount) { return { individual: { reducedAmount: null, deduction: null }, business: null } }
      let deduction = null
      let reducedAmount = null
      if (this.currentAmount.value > 250) {
        let excess = this.currentAmount.subtract(250)
        reducedAmount = currency(excess).multiply(0.4).add(200)
        deduction = this.currentAmount.subtract(reducedAmount)
      } else {
        reducedAmount = this.currentAmount.multiply(0.8)
        deduction = this.currentAmount.subtract(reducedAmount)
      }
      let businessRelief = this.currentAmount.multiply(0.4)
      return { individual: { reducedAmount, deduction }, business: businessRelief }
    },
    itTaxCalc () {
      if (!this.currentAmount) { return { individual: { reducedAmount: 0, deduction: 0 }, business: 0 } }
      // 30% of donation with limit of 30K (if > €30K donation show message)
      if (this.currentAmount.value > 30000) {// will need to convert str
        return { individual: 0, business: 0 }
      } else {
        let reducedAmount = this.currentAmount.multiply(0.7)
        let deduction = this.currentAmount.subtract(reducedAmount)
        return { individual: { reducedAmount, deduction }, business: 0 }
      }
    },
    frTaxCalc () {
      // 66%
      if (!this.currentAmount) { return { individual: { reducedAmount: 0, deduction: 0 }, business: 0 } }
      let reducedAmount = this.currentAmount.multiply(0.66)
      let deduction = this.currentAmount.subtract(reducedAmount)
      return { individual: { reducedAmount, deduction }, business: 0 }
    }
  },
  methods: {
    formatCurrency (value, curr) {
      return this.$l10n.formatCurrency(value, curr, { precision: 1 })
    },
  }
}
</script>

<style lang="scss" scoped>
  $text-color: #fff;

  .relief-calc {
    font-size: 0.9rem;
    background: rgba(255,255,255,0.3);
    color: $text-color;
    padding: 0.75rem;
    margin-top: 1rem;
  }
</style>
